<template>
  <div class="static space-y-10">
    <div class="text-center grid justify-items-center space-y-4">
      <h2 class="text-3xl geminis">{{ $t('presentation.history.title') }}</h2>
      <span class="text-xl font-bold">{{ currentHistory.date }}</span>
      <p class="lg:w-1/2 text-left lg:text-center h-36 lg:h-20">
        {{ currentHistory.description }}
      </p>
    </div>
    <div class="relative flex items-center justify-between px-6 bottom-56 lg:top-20">
      <div @click="previousHistory" class="hover:cursor-pointer hover:text-gray-400 hover:scale-125">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
      </div>
      <div @click="nextHistory" class="hover:cursor-pointer hover:text-gray-400 hover:scale-125">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
      </div>
    </div>
    <div class="hidden lg:flex items-center px-24">
      <div v-for="item in history" :key="item.id" class="flex flex-row-reverse items-center" :class="{'w-full' : !isFirstStep(item.id)}">
        <div class="flex items-center text-teal-600 relative">
          <div
            class="rounded-full transition duration-500 ease-in-out h-8 w-8 py-3 cursor-pointer"
            :class="{'bg-white': !isCurrentOrPreviousStep(item.id), 'bg-space-green': isCurrentOrPreviousStep(item.id)}"
            @click="currentHistory = item"
          >
          </div>
          <div class="absolute -ml-1 top-0 text-center mt-10 w-10 font-bold uppercase">{{ item.date }}</div>
        </div>
        <div v-if="!isFirstStep(item.id)"
             class="flex-auto border transition duration-500 ease-in-out"
             :class="{'border-white': !isCurrentOrPreviousStep(item.id), 'border-space-green': isCurrentOrPreviousStep(item.id)}"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HistoryView",
  props: {
    history: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentHistory: this.history[0]
    };
  },
  methods : {
    isFirstStep(id) {
      return id === 1;
    },
    isCurrentOrPreviousStep(id) {
      return id <= this.currentHistory.id;
    },
    nextHistory() {
      if (this.currentHistory.id < this.history.length) {
        this.currentHistory = this.history[this.currentHistory.id];
      }
    },
    previousHistory() {
      if (this.currentHistory.id > 1) {
        this.currentHistory = this.history[this.currentHistory.id - 2];
      }
    }
  }
};
</script>
