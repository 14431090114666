<template>
  <div class="w-full flex flex-col items-center justify-center">
    <img
      :data-src="require('@/assets/images/dotcode.png')"
      alt="Dotcode"
      class="z-10 w-1/2 md:w-auto"
    >
    <div class="geminis">
      Made with love by l'équipe 12
    </div>
    <div class="geminis">
      2022, Dotcode
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterLogo'
};
</script>

<style scoped>

</style>
