<template>
  <HomeLayout class="space-y-14">
    <template #header>
      <div class="h-screen w-full text-left flex flex-col items-center md:items-end !bg-cover" :style="{background: `url('${require('@/assets/images/SpaceCode_quisommesnous_pagebg-01.svg')}') no-repeat`}">
        <div class="w-2/3 md:w-8/12 lg:w-2/5 xl:w-1/3 h-full lg:float-right flex items-center right-1/3 md:right-0">
          <h1 class="geminis text-6xl md:text-7xl lg:text-8xl 2xl:text-9xl text-shadow mb-72 2xl:mb-96">{{ $t('presentation.title') }}</h1>
        </div>
      </div>
    </template>
    <template #content>
      <div class="space-y-24 md:mt-10">
        <div class="grid lg:grid-cols-2 gap-4">
          <div class="lg:col-span-1 space-y-2 text-left">
            <h2 class="text-3xl geminis uppercase">{{ $t('presentation.project.title') }}</h2>
            <p>
              {{$t('presentation.project.paragraph.1')}}<br><br>
              {{$t('presentation.project.paragraph.2')}}<br><br>
              {{$t('presentation.project.paragraph.3')}}
            </p>
            <p class="text-space-green italic font-bold text-lg">{{$t('presentation.project.paragraph.4')}}</p>
            <p>{{$t('presentation.project.paragraph.5')}}</p>
          </div>
          <div class="hidden lg:block col-span-1 rounded-xl">
            <img :data-src="require('@/assets/images/header_quisommesnous.svg')"
                 width="100%"
                 height="100%"
            />
          </div>
        </div>
        <div class="text-center space-y-10">
          <h2 class="text-3xl geminis">{{ $t('presentation.value.title') }}</h2>
          <div class="grid justify-items-center space-y-10">
            <ValueCard class="lg:w-1/2" v-for="item in valueData" :key="item.id" :title="item.title" :text="item.text" :imgUrl="item.imgUrl"/>
          </div>
        </div>
        <HistoryView :history="historyData" />
        <div class="text-center space-y-10">
          <div>
            <h2 class="text-3xl geminis mt-44">{{ $t('presentation.team.title') }}</h2>
          </div>
          <div class="grid grid-cols-2 lg:grid-cols-3 gap-y-10 gap-x-4 xl:px-44">
            <TeamCard class="col-span-1" v-for="team in teamData" :key="team.id" :team="team" />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="space-y-10 mt-14">
        <div class="w-full bg-white px-2 lg:px-32 py-14">
          <div class="text-black text-center space-y-14">
            <h2 class="text-2xl lg:text-4xl geminis">{{ $t('presentation.join_card.title') }}</h2>
            <p>
              {{ $t('presentation.join_card.paragraph.1.1') }} <br>
              {{ $t('presentation.join_card.paragraph.1.2') }}
            </p>
            <p>
              {{ $t('presentation.join_card.paragraph.2') }}
            </p>
            <PrimaryButton
              classes="bg-space-dark-blue text-white hover hover:text-space-dark-blue"
              :text="$t('presentation.join_card.button')"
            />
          </div>
        </div>
        <FooterLogo />
      </div>
    </template>
  </HomeLayout>
</template>

<script>
import HomeLayout from "@/layouts/HomeLayout";
import ValueCard from "@/components/Edito/presentation/ValueCard";
import { Team, Values, History } from '@/constants/edito/PresentationConstants';
import PrimaryButton from '@/components/buttons/PrimaryButton';
import TeamCard from '@/components/Edito/presentation/TeamCard';
import FooterLogo from '@/components/FooterLogo';
import HistoryView from '@/components/Edito/presentation/HistoryView';
export default {
  name: "Presentation",
  components: { HistoryView, FooterLogo, TeamCard, PrimaryButton, ValueCard, HomeLayout},
  data () {
    return {
      valueData : Values,
      teamData: Team,
      historyData: History
    };
  }
};
</script>

<style scoped>
.hover:hover {
  background-color: #FFFFFF !important;
}
</style>
