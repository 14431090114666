<template>
  <div class="flex flex-col lg:flex-row items-center space-y-4 lg:space-x-4 lg:space-y-0">
    <div class="w-24 md:h-36 h-24 md:w-36 rounded-lg">
      <img :data-src="require(`@/assets/images/`+imgUrl)" alt="valueImage"
           width="100%"
           height="100%"
      />
    </div>
    <div class="text-left lg:w-3/5 space-y-2">
      <h5 class="font-bold text-xl">{{title}}</h5>
      <p class="text-base">{{text}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ValueCard",
  props : {
    title: String,
    text: String,
    imgUrl: String
  },
};
</script>

<style scoped>

</style>
