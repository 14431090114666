import i18n from "@/i18n/i18n";

export const Values = [
  {
    id: 1,
    title : i18n.t('presentation.value.fun.title'),
    text : i18n.t('presentation.value.fun.text'),
    imgUrl: 'astrofun_illuvaleur1.svg'
  },
  {
    id: 2,
    title: i18n.t('presentation.value.competition.title'),
    text: i18n.t('presentation.value.competition.text'),
    imgUrl: 'astrocompetitif_illuvaleur2.svg'
  },
  {
    id: 3,
    title: i18n.t('presentation.value.spirit.title'),
    text: i18n.t('presentation.value.spirit.text'),
    imgUrl: 'astroopenmind_illuvaleur3.svg'
  },
  {
    id: 4,
    title: i18n.t('presentation.value.passion.title'),
    text: i18n.t('presentation.value.passion.text'),
    imgUrl: 'astropassion_illuvaleur4.svg'
  }
];

export const Team = [
  {
    id: 1,
    firstname: 'Melanie',
    lastname: 'Auray',
    post: i18n.t('presentation.team.post.uxui'),
    image: 'melanie_auray_prez.webp',
    linkedin : 'https://www.linkedin.com/in/melanieauray/'
  },
  {
    id: 2,
    firstname: 'Noa',
    lastname: 'Devillers',
    post: i18n.t('presentation.team.post.comm'),
    image: 'SpaceCode_AstroWin.svg',
    linkedin : null
  },
  {
    id: 3,
    firstname: 'Nicolas',
    lastname: 'Brazzolotto',
    post: i18n.t('presentation.team.post.full'),
    image: 'nicolas_brazzolotto_prez.webp',
    linkedin : 'https://www.linkedin.com/in/nicolas-brazzolotto-1b4109183/'
  },
  {
    id: 4,
    firstname: 'Julien',
    lastname: 'Dupont',
    post: i18n.t('presentation.team.post.full'),
    image: 'julien_dupont_prez.webp',
    linkedin : 'https://www.linkedin.com/in/julien-dupont-00/'
  },
  {
    id: 5,
    firstname: 'Antoine',
    lastname: 'Durussel',
    post: i18n.t('presentation.team.post.full'),
    image: 'antoine_durussel_prez.webp',
    linkedin : 'https://www.linkedin.com/in/antoine-durussel/'
  },
  {
    id: 6,
    firstname: 'Benjamin',
    lastname: 'Pichon',
    post: i18n.t('presentation.team.post.front'),
    image: 'benjamin_pichon_prez.webp',
    linkedin : 'https://www.linkedin.com/in/benjamin-pichon-0178121a5/'
  },
];

export const History = [
  {
    id: 1,
    date: 'SEP 2021',
    description: i18n.t('presentation.history.1')
  },
  {
    id: 2,
    date: 'DEC 2021',
    description: i18n.t('presentation.history.2')
  },
  {
    id: 3,
    date: 'JAN 2022',
    description: i18n.t('presentation.history.3')
  },
  {
    id: 4,
    date: 'SEP 2022',
    description: i18n.t('presentation.history.4')
  },
  {
    id: 5,
    date: 'JAN 2023',
    description: i18n.t('presentation.history.5')
  },
  {
    id: 6,
    date: 'JUIL 2023',
    description: i18n.t('presentation.history.6')
  }
];
