<template>
  <div class="flex flex-col items-center">
    <div>
      <img v-if="team.image" class="w-24 lg:h-44 h-24 lg:w-44 rounded-lg relative z-0" :data-src="require(`@/assets/images/`+team.image)"  alt="team_image"
           width="100%"
           height="100%"
      />
      <div v-else class="w-24 lg:h-44 h-24 lg:w-44 bg-white rounded-lg"/>
      <a v-if="hasLinkedin" :href="team.linkedin" class="flex items-center justify-center text-center bg-space-green rounded-full w-8 h-8 float-right -mt-11 -mr-3.5 relative z-10" target="_blank" rel="noopener noreferrer">
        <p class="font-bold">in</p>
      </a>
    </div>
    <div>
      <p class="font-bold">{{ team.firstname }} {{ team.lastname }}</p>
      <span>{{ team.post }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamCard',
  props: {
    team : {
      type : Object,
      required : true
    }
  },
  computed : {
    hasLinkedin() {
      return this.team.linkedin !== null;
    }
  }
};
</script>
